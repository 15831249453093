var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"partMeeting-container"},[_c('van-sticky',[_c('head-bar',{attrs:{"type":"primary","leftArrow":true,"left-text":"","title":"照片直播"},on:{"onClickLeft":_vm.onClickLeft}})],1),_c('div',{staticClass:"main"},[_c('div',{on:{"click":function($event){return _vm.goLive(_vm.list[0].pliveUrl)}}},[_c('div',{staticClass:"part-item",style:({
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081629103611077.png)`,
          })})]),_c('div',{on:{"click":function($event){return _vm.goLive(_vm.list[1].pliveUrl)}}},[_c('div',{staticClass:"part-item",style:({
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081629262485499.png)`,
          })})]),_c('div',{on:{"click":function($event){return _vm.goLive(_vm.list[2].pliveUrl)}}},[_c('div',{staticClass:"part-item",style:({
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081629397083203.png)`,
          })})]),_c('div',{on:{"click":function($event){return _vm.goLive(_vm.list[3].pliveUrl)}}},[_c('div',{staticClass:"part-item",style:({
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081630013642943.png)`,
          })})]),_c('div',{on:{"click":function($event){return _vm.goLive(_vm.list[4].pliveUrl)}}},[_c('div',{staticClass:"part-item",style:({
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081628560084544.png)`,
          })})]),_c('div',{on:{"click":function($event){return _vm.goLive(_vm.list[5].pliveUrl)}}},[_c('div',{staticClass:"part-item part-item-first",style:({
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081627369870780.png)`,
          })})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }