<template>
  <div class="partMeeting-container">
    <van-sticky>
      <head-bar
        type="primary"
        :leftArrow="true"
        left-text=""
        title="照片直播"
        @onClickLeft="onClickLeft"
      />
    </van-sticky>
    <div class="main">
<!--      <div class="part-item-first" v-if="list[0]" @click="goLive(list[0].pliveUrl)">-->
        <!-- <div class="info-item info-place">
          <span class="item-place">中国·长沙</span>
          <span>2023年8月31-01日</span>
        </div>
        <div class="title">{{list[0].name}}</div>
        <div class="info-item info-time">
          <img class="icon-clock" :src="require('@/assets/images/partMeeting/icon-clock.png')" alt="">
          <span>{{list[0].startDate.substr(5,5)}} {{list[0].startTime.substr(0,5)}}-{{list[0].endTime.substr(0,5)}}</span>
        </div>
        <div class="info-item">
          <img class="icon-live" :src="require('@/assets/images/partMeeting/icon-live.png')" alt="">
          <span>进入直播</span>
        </div> -->
<!--      </div>-->
      <!-- <div v-for="(item, index) in list" :key="index" @click="goLive(item.pliveUrl)">
        <div
          class="part-item"
          :class="{'part-item-first': index === 0}"
          :style="{
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/ylfh2023/${index + 1}-${type}.png?time=${new Date().getTime()})`,
          }"
        >
        </div>
      </div> -->
      <div @click="goLive(list[0].pliveUrl)">
        <div
          class="part-item "
          :style="{
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081629103611077.png)`,
          }"
        >
        </div>
      </div>
      <div  @click="goLive(list[1].pliveUrl)">
        <div
          class="part-item"
          :style="{
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081629262485499.png)`,
          }"
        >
        </div>
      </div>
      <div  @click="goLive(list[2].pliveUrl)">
        <div
          class="part-item"
          :style="{
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081629397083203.png)`,
          }"
        >
        </div>
      </div>
      <div  @click="goLive(list[3].pliveUrl)">
        <div
          class="part-item"
          :style="{
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081630013642943.png)`,
          }"
        >
        </div>
      </div>
      <div  @click="goLive(list[4].pliveUrl)">
        <div
          class="part-item"
          :style="{
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081628560084544.png)`,
          }"
        >
        </div>
      </div>
      <div  @click="goLive(list[5].pliveUrl)">
        <div
          class="part-item part-item-first"
          :style="{
            backgroundImage: `url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409081627369870780.png)`,
          }"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headBar from "@/components/headBar/headBar";
import { Dialog, Toast } from "vant";
export default {
  components: { headBar },
  data() {
    return {
      list: [
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' }
      ],
      type: 'live', 
    };
  },
  mounted() {
    this.getLiveList();
  },
  methods: {
    onClickLeft() {
      this.$router.push("/index/" + this.$store.state.miceInfo.miceLink);
    },
    async getLiveList() {
      this.loading = true;
      let result = await this.$http.get(
        this.$baseURL.index.getGroupList() +
          "?miceLink=" +
          this.$store.state.miceInfo.miceLink,
        1
      );
      this.loading = false;
      if (result.code === 200) {
        result.data.forEach((item) => {
          if (item.display === 1) {
            this.list.push(item);
          }
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: result.message,
        }).then(() => {
          // on close
        });
      }
    },
    goLive(url) {
      if (url) {
        window.location.href = url;
      } else {
        Toast("敬请期待！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.partMeeting-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: url("https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/ylfh2023/bg.jpeg?time=2")
    no-repeat;
  background-size: 100% 100%;
  .main {
    flex: 1;
    padding-bottom: 25px;
    overflow-y: auto;
    .part-item {
      margin: 25px auto 0;
      padding: 20px 0;
      width: 325px;
      height: 80px;
      border-radius: 10px;
      overflow: hidden;
      background-size: 100% 100%;
      color: #ffffff;
      .title {
        margin-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }
      .info {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 9px;
        .info-item {
          display: flex;
          align-items: center;
          &:nth-of-type(1) {
            margin-right: 15px;
          }
          img {
            margin-right: 5px;
          }
          .icon-clock {
            width: 9px;
            height: 9px;
          }
          .icon-live {
            width: 11px;
            height: 8px;
          }
        }
      }
    }
    .part-item-active {
      background: url("https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202208291156002947818.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
    }
    .part-item-first {
      margin: 25px auto 0;
      background: url("https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/ylfh2023/1-live.png?time=2")
        no-repeat;
      background-size: 100% 100%;
      width: 325px;
      height: 152px;
      color: #ffffff;
      overflow: hidden;
      .title {
        font-size: 16px;
        text-align: center;
      }
      .info-item {
        text-align: center;
        font-size: 9px;
        .item-place {
          margin-right: 24px;
        }
        .icon-clock {
          width: 9px;
          height: 9px;
          margin-right: 5px;
        }
        .icon-live {
          width: 11px;
          height: 8px;
          margin-right: 5px;
        }
      }
      .info-place {
        margin: 67px 0 10px;
      }
      .info-time {
        margin: 8px 0 6px;
      }
    }
  }
}
</style>
